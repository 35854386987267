<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fas fa-home',
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-show="checkAccess('media', 'edit') || checkAccess('media', 'view')"
          :link="{ name: 'Media', path: '/media', icon: 'far fa-image ' }"
        />
        <sidebar-item
          v-show="checkAccess('cms', 'edit') || checkAccess('cms', 'view')"
          :link="{
            name: 'Content',
            icon: 'far fa-file-alt ',
          }"
        >
          <sidebar-item
            :link="{ name: 'Retreats', path: '/content/retreats' }"
          />
          <sidebar-item
            :link="{ name: 'Treatments', path: '/content/treatments' }"
          />
          <sidebar-item
            :link="{ name: 'Activities', path: '/content/activities' }"
          />
          <sidebar-item :link="{ name: 'Videos', path: '/content/videos' }" />
          <sidebar-item
            :link="{
              name: 'Recipe Categories',
              path: '/content/categories/recipe',
            }"
          />
          <sidebar-item :link="{ name: 'Recipes', path: '/content/recipes' }" />
          <sidebar-item
            :link="{
              name: 'Handout Categories',
              path: '/content/categories/handout',
            }"
          />
          <sidebar-item
            :link="{ name: 'Handouts', path: '/content/handouts' }"
          />
          <sidebar-item
            :link="{
              name: 'Health Conditions',
              path: '/content/healthstatuses',
            }"
          />
            <sidebar-item
            :link="{
              name: 'Privacy Policy',
              path: '/content/policy',
            }"
          />
             <sidebar-item
            :link="{
              name: 'T&C',
              path: '/content/terms',
            }"
          />

          <sidebar-item
            :link="{
              name: 'Key Values',
              path: '/content/cms',
            }"
          />
          
        </sidebar-item>

        <sidebar-item
          v-show="
            checkAccess('guests', 'edit') || checkAccess('guests', 'view')
          "
          :link="{ name: 'Guests', path: '/guests', icon: 'far fa-user ' }"
        />
        <sidebar-item
          v-show="
            checkAccess('guests', 'edit') || checkAccess('guests', 'view')
          "
          :link="{ name: 'Stays', path: '/stays', icon: 'fa fa-bed ' }"
        />

           <sidebar-item
          v-show="
            checkAccess('guests', 'edit') || checkAccess('guests', 'view')
          "
          :link="{ name: 'Stay sessions', path: '/staysessions', icon: 'fas fa-user-clock' }"
        />


        <sidebar-item
          v-show="
            checkAccess('checkin', 'edit') || checkAccess('checkin', 'view')
          "
          :link="{
            name: 'Check In',
            path: '/check-in',
            icon: 'fas fa-clipboard-check',
          }"
        />

        <sidebar-item
          v-show="
            checkAccess('personnel', 'edit') || checkAccess('personnel', 'view')
          "
          :link="{
            name: 'Manage Staff',
            icon: 'fas fa-user-md ',
          }"
        >
          <sidebar-item
            v-show="
              checkAccess('personnel', 'edit') ||
              checkAccess('personnel', 'view')
            "
            :link="{ name: 'Personnel', path: '/staff/personnel' }"
          />
          <!--<sidebar-item :link="{ name: 'Roles', path: '/security/roles' }"/> -->
          <!--<sidebar-item :link="{ name: 'My Profile', path: '/security/me' }"/> -->
        </sidebar-item>

        <!--<sidebar-item
          :link="{
            name: 'Notifications',
            icon: 'fas fa-bell ',
          }"
        >
          <sidebar-item
            :link="{ name: 'Devices', path: '/notifications/devices' }"
          />
          <sidebar-item
            :link="{ name: 'History', path: '/notifications/history' }"
          />
          <sidebar-item
            :link="{ name: 'Queue', path: '/notifications/queue' }"
          />
        </sidebar-item>-->

        <!-- <sidebar-item
          :link="{
            name: 'Eventilla',
             path: '/eventilla',
            icon: 'far fa-calendar-alt ',

          }"
        > 

        </sidebar-item>-->

        <sidebar-item
          :link="{
            name: 'Notifications',
            icon: 'fas fa-bell ',

          }"
        >
          <sidebar-item :link="{ name: 'Devices', path: '/notifications/devices' }"/>
          <sidebar-item :link="{ name: 'History', path: '/notifications/history' }"/>
          <sidebar-item :link="{ name: 'Queue', path: '/notifications/queue' }"/>
          <sidebar-item :link="{ name: 'Add Message(s)', path: '/notifications/send' }"/>
        </sidebar-item>


        <sidebar-item
          v-show="checkAccess('users', 'edit') || checkAccess('users', 'view')"
          :link="{
            name: 'Security',
            icon: 'fas fa-shield-alt ',
          }"
        >
          <sidebar-item
            v-show="
              checkAccess('users', 'edit') || checkAccess('users', 'view')
            "
            :link="{ name: 'Users', path: '/security/users' }"
          />
          <sidebar-item :link="{ name: 'Roles', path: '/security/roles' }"/>
          <!--<sidebar-item :link="{ name: 'My Profile', path: '/security/me' }"/> -->
        </sidebar-item>
      </template>

      <!--
      <b-nav-item href="https://www.creative-tim.com/learning-lab/bootstrap-vue/charts/argon-dashboard">
          <i class="ni ni-chart-pie-35"></i>
          <b-nav-text class="p-0">Plugins</b-nav-text>
      </b-nav-item>
    -->
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss">
</style>
