<template>
  <div>
    <validation-provider
      :rules="required ? 'required' : ''"
      :name="name"
      v-bind="$attrs"
      v-slot="{ errors, valid, invalid, validated }"
    >
      <b-form-group :class="{ 'mb-0': nomargin }">
        <slot name="label">
          <label v-if="label" :class="labelClasses">
            {{ label }}<span v-if="required">&nbsp;*</span>
          </label>
        </slot>
        <slot v-bind="slotData">
            
          <MultiSelect
            :multiple="multiple"
           :name="name"
            v-model="inputVal"
            label="name"
            :track-by="trackby"
            @input="onChange"
            :placeholder="placeholder"
            :options="options"
            :class="[
                { 'is-valid': valid && validated && successMessage },
                { 'is-invalid': invalid && validated },
                
              ]"
          >
          </MultiSelect>

          <slot name="success">
            <div
              class="valid-feedback"
              v-if="valid && validated && successMessage"
            >
              {{ successMessage }}
            </div>
          </slot>
          <slot name="error">
            <div
              v-if="errors[0]"
              class="invalid-feedback"
              style="display: block"
            >
           
          {{ errors[0] }}
        
            </div>
          </slot>
        </slot>
      </b-form-group>
    </validation-provider>
  </div>
</template>
 
<script>
import MultiSelect from "vue-multiselect";
export default {
  components: { MultiSelect },
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    rules: {
      type: [String, Array, Object],
      description: "Vee validate validation rules",
      default: "",
    },
    name: {
      type: String,
      description: "Input name (used for validation)",
      default: "",
    },
      nomargin: {
      type: Boolean
    },
     required: {
      type: Boolean
    },
    trackby: {
      type: String,
      default: "value",
    },
     error: {
      type: String,
      description: "Input error (below input)",
    },
    successMessage: {
      type: String,
      description: "Input success message",
      default: "",
    },
    multiple: {
      type: Boolean,
      default: true,
      description: "Whether input is required (adds an asterix *)",
    },
    required: {
      type: Boolean,
      description: "Whether input is required (adds an asterix *)",
    },
    value: { //make it any
    },
    label: {
      type: String,
      description: "Label",
    },
    options: {
      type: Array,
      description: "Array of options",
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
      default: "form-control-label",
    },
  },
  data() {
    return {
      // data: [...this.selected]
    };
  },
  methods: {
       onChange (value) {
        this.$emit('change', value)
    },

     updateValue(evt) {
       console.log("update")
       console.log(evt)
      //let value = evt;
      this.$emit("changed", this.value);
    },
    onFocus(evt) {
      console.log("focus")
      this.focused = true;
      this.$emit("focus", evt);
    },
    onBlur(evt) {
      console.log("blur")
      this.focused = false;
      this.$emit("blur", evt);
    },
  },
  computed: {

    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
       // focus: this.onFocus,
       // blur: this.onBlur,
      };
    },
    slotData() {
      return {
        error: this.error,
        ...this.listeners,
      };
    },

    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
 

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css">
</style>

<style lang="scss">
.multiselect.is-invalid {
  .multiselect__tags {
border-color:red;
  }

}
.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 12px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #dee2e6;
  background: #fff;
  font-size: 14px;
}
.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #3897b6;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
</style>

