
export const routeAuthentication = "Authentication"
export const routeLogin = "Login"
export const routeHome = "Home"
export const routeMain = "Main"
export const routeDashboard = "Dashboard"
export const routeRoles = "Roles"

export const routeUsers = "Users"
export const routeUsersNew = "Users_New"
export const routeUsersEdit = "Users-Edit"

export const routeVideos = "Videos"
export const routeVideosNew = "Videos-New"
export const routeVideosEdit = "Videos-Edit"

export const routeRetreats = "Retreats"
export const routeRetreatsNew = "Retreats-New"
export const routeRetreatsEdit = "Retreats-Edit"

export const routeActivities = "Activities"
export const routeActivitiesNew = "Activities-New"
export const routeActivitiesEdit = "Activities-Edit"

export const routeTreatments = "Treatments"
export const routeTreatmentsNew = "Treatments-New"
export const routeTreatmentsEdit = "Treatments-Edit"

export const routeHealthStatuses = "HealthStatuses"
export const routeHealthStatusesNew = "HealthStatuses-New"
export const routeHealthStatusesEdit = "HealthStatuses-Edit"

export const routePersonnel = "Personnel"
export const routePersonnelNew = "Personnel-New"
export const routePersonnelEdit = "Personnel-Edit"

export const routeMedia = "Media"
export const routeMediaNew = "Media-New"
export const routeMediaEdit = "Media-Edit"

export const routeHandouts = "Handouts"
export const routeHandoutsNew = "Handouts-New"
export const routeHandoutsEdit = "Handouts-Edit"

export const routeRecipes = "Recipes"
export const routeRecipesNew = "Recipes-New"
export const routeRecipesEdit = "Recipes-Edit"

export const routeCategoriesHandout = "CategoriesHandout"
export const routeCategoriesHandoutNew = "CategoriesHandout-New"
export const routeCategoriesHandoutEdit = "CategoriesHandout-Edit"

export const routeCategoriesRecipe = "CategoriesRecipes"
export const routeCategoriesRecipeNew = "CategoriesRecipes-New"
export const routeCategoriesRecipeEdit = "CategoriesRecipes-Edit"

export const routeGuests = "Guests"
export const routeGuestsNew = "GuestsNew"
export const routeGuestMain = "GuestsEdit"
export const routeGuestProfile = "GuestProfile"

export const routeGuestFiles = "GuestFiles"
export const routeGuestFilesNew = "GuestFilesNew"
export const routeGuestFilesEdit = "GuestFilesEdit"

export const routeGuestMedicalTests = "GuestMedicalTests"
export const routeGuestMedicalTestsNew = "GuestMedicalTestsNew"
export const routeGuestMedicalTestsEdit = "GuestMedicalTestsEdit"

export const routeGuestHealthStatus = "GuestHealthStatus"
export const routeGuestHealthStatusNew = "GuestHealthStatusNew"
export const routeGuestHealthStatusEdit = "GuestHealthStatusEdit"

export const routeGuestStayNew = "GuestStayNew"
export const routeGuestStays = "GuestStays"
export const routeGuestStayEdit = "GuestStayEdit"

export const routeGuesWellnessPlanNew = "GuesWellnessPlanNew"
export const routeGuesWellnessPlan = "GuesWellnessPlan"
export const routeGuesWellnessPlanEdit = "GuesWellnessPlanEdit"

export const routeCheckIn = "CheckIn"


export const routeAllStays = "AllStays"
export const routeAllSessions = "AllSessions"


export const routePolicyEdit = "PolicyEdit"
export const routeTermsEdit = "TermsEdit"

export const routeRoleEdit = "RoleEdit"

export const routeGuestWellnessPlan  = 'Guest Wellness Plan'
export const routeGuestWellnessPlanEdit = 'Guest Wellness Plan Edit'
export const routeGuestWellnessPlanNew  = 'New Guest Wellness Plan'


export const routeReset = "Reset"

export const surveys = "Surveys"
export const surveyView = "SurveyView"
export const cms = "CMS"
export const cmsNew = "CMSNEW"
export const cmsEdit = "CMSEDIT"

export const notifications = "Notifications"
export const devices = "Devices"
export const history = "History"
export const sendMessage = "Add Message(s)"