import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';
import * as routeNames from "@/routes/route-names";

const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Dashboard.vue');

const Users = () => import(/* webpackChunkName: "users" */ '@/views/Pages/Users/Users.vue');
const UsersEdit = () => import(/* webpackChunkName: "users" */ '@/views/Pages/Users/UsersEdit.vue');

const Categories = () => import(/* webpackChunkName: "categories" */ '@/views/Pages/Categories/Categories.vue');
const CategoriesEdit = () => import(/* webpackChunkName: "categories" */ '@/views/Pages/Categories/CategoriesEdit.vue');

const Retreats = () => import(/* webpackChunkName: "retreats" */ '@/views/Pages/Retreats/Retreats.vue');
const RetreatsEdit = () => import(/* webpackChunkName: "retreats" */ '@/views/Pages/Retreats/RetreatsEdit.vue');

const Videos = () => import(/* webpackChunkName: "videos" */ '@/views/Pages/Videos/Videos.vue');
const VideosEdit = () => import(/* webpackChunkName: "videos" */ '@/views/Pages/Videos/VideosEdit.vue');

const Treatments = () => import(/* webpackChunkName: "treatments" */ '@/views/Pages/Treatments/Treatments.vue');
const TreatmentsEdit = () => import(/* webpackChunkName: "treatments" */ '@/views/Pages/Treatments/TreatmentsEdit.vue');

const Activities = () => import(/* webpackChunkName: "activities" */ '@/views/Pages/Activities/Activities.vue');
const ActivitiesEdit = () => import(/* webpackChunkName: "activities" */ '@/views/Pages/Activities/ActivitiesEdit');

const Recipes = () => import(/* webpackChunkName: "recipes" */ '@/views/Pages/Recipes/Recipes.vue');
const RecipesEdit = () => import(/* webpackChunkName: "recipes" */ '@/views/Pages/Recipes/RecipesEdit.vue');

const Handouts = () => import(/* webpackChunkName: "handouts" */ '@/views/Pages/Handouts/Handouts.vue');
const HandoutsEdit = () => import(/* webpackChunkName: "handouts" */ '@/views/Pages/Handouts/HandoutsEdit.vue');

const Roles = () => import(/* webpackChunkName: "roles" */ '@/views/Pages/Users/Roles.vue');
const RoleEdit = () => import(/* webpackChunkName: "roles" */ '@/views/Pages/Users/RoleEdit.vue');

const Media = () => import(/* webpackChunkName: "media" */ '@/views/Pages/Media/Media.vue');
const MediaEdit = () => import(/* webpackChunkName: "media" */ '@/views/Pages/Media/MediaEdit.vue');

const Login = () => import(/* webpackChunkName: "login" */ '@/views/Pages/Login.vue');
const Reset = () => import(/* webpackChunkName: "resetPassword" */ '@/views/Pages/ResetPassword.vue');

const PersonnelList = () => import(/* webpackChunkName: "personnel" */ '@/views/Pages/Personnel/PersonnelList.vue');
const PersonnelEdit = () => import(/* webpackChunkName: "personnel" */ '@/views/Pages/Personnel/PersonnelEdit.vue');

const HealthstatusList = () => import(/* webpackChunkName: "healthstatus" */ '@/views/Pages/Healthstatus/HealthstatusList.vue');
const HealthstatusEdit = () => import(/* webpackChunkName: "healthstatus" */ '@/views/Pages/Healthstatus/HealthstatusEdit.vue');

const StaysList = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/Stays/StaysList.vue');

const GuestsList = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/Guests/GuestsList.vue');
const GuestDetails = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/Guests/GuestDetails.vue');
const GuestEdit = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/Guests/GuestEdit.vue');

const CheckIn = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/Guests/GuestCheckIn.vue');


const GuestNew = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/Guests/GuestNew.vue');
const GuestsStayAdd = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/Guests/GuestStayAdd.vue');
const GuestStays = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/Guests/GuestStays.vue');
const GuestStayEdit = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/Guests/GuestStayEdit.vue');
const GuestMedia = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/Guests/GuestMedias.vue');
const GuestMediaEdit = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/Guests/GuestMediaEdit.vue');
const GuestHealthStatusEdit = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/Guests/GuestHealthStatusEdit.vue');
const GuestHealthStatuses = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/Guests/GuestHealthStatuses.vue');
const GuestMedicalResults = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/Guests/GuestMedicalResults.vue');
const GuestMedicalResulEdit = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/Guests/GuestMedicalResultEdit.vue');


const GuestWellnessPlans = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/Guests/GuestWellnessPlans.vue');
const GuestWellnessPlan = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/Guests/GuestWellnessPlan.vue');



const PolicyEdit = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/GenericContent/PrivacyPolicyEdit.vue');
const TermsEdit = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/GenericContent/TermsEdit.vue');
const StaySessions = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/StaySessions/StaysessionList.vue');
 
const Surveys = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/Guests/GuestSurveys.vue');
const SurveyView = () => import(/* webpackChunkName: "guests" */ '@/views/Pages/Guests/GuestSurveyDetails.vue');
const CMS = () => import(/* webpackChunkName: "cms" */ '@/views/Pages/CMS/cms.vue');
const CmsEdit = () => import(/* webpackChunkName: "cms" */ '@/views/Pages/CMS/cmsEdit.vue');
const Devices = () => import(/* webpackChunkName: "devices" */ '@/views/Pages/Notifications/Devices.vue');
const Notifications = () => import(/* webpackChunkName: "notifications" */ '@/views/Pages/Notifications/MessageQueue.vue');
const History = () => import(/* webpackChunkName: "history" */ '@/views/Pages/Notifications/MessageHistory.vue');
const SendMessage = () => import(/* webpackChunkName: "sendMessage" */ '@/views/Pages/Notifications/SendMessage.vue');




let authPages = {
  path: '/',
  component: AuthLayout,
  name: routeNames.routeAuthentication,
  children: [
    /*{
      path: '/home',
      name: 'Home',
      component: Home,
      meta: {
        noBodyBackground: true
      }
    },*/
    {
      path: '/login',
      name: routeNames.routeLogin,
      component: Login
    },
    {
      path: '/reset-password',
      name: routeNames.routeReset,
      component: Reset
    },
    { path: '*', component: NotFound }
  ]
};


const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: routeNames.routeHome
  },
  //componentsMenu,
  //formsMenu,
  //tablesMenu,
  //mapsMenu,
  //pagesMenu,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: routeNames.routeMain,
    children: [
      {
        path: 'dashboard',
        name: routeNames.routeDashboard,
        component: Dashboard,
      },
      /*   ROUTES SECURITY */
      {
        path: '/security/roles',
        name: routeNames.routeRoles,
        component: Roles,
      },
      {
        path: '/security/roles/new',
        name: routeNames.routeRoleEdit,
        component: RoleEdit,
      },
      {
        path: '/security/roles/:id',
        name: routeNames.routeRoleEdit,
        component: RoleEdit,
      },

      {
        path: '/security/users',
        name: routeNames.routeUsers,
        component: Users,
      },
      {
        path: '/security/users/new',
        name: routeNames.routeUsersNew,
        component: UsersEdit,
      },
      {
        path: '/security/users/:id',
        name: routeNames.routeUsersEdit,
        component: UsersEdit,
      },
      /*   ROUTES SECURITY END */
        /*   ROUTES CONTENT */
      {
        path: '/content/videos',
        name: routeNames.routeVideos,
        component: Videos,

      },
      {
        path: '/content/videos/new',
        name: routeNames.routeVideosNew,
        component: VideosEdit,
      },
      {
        path: '/content/videos/:id',
        name: routeNames.routeVideosEdit,
        component: VideosEdit,
      },

      {
        path: '/content/retreats',
        name: routeNames.routeRetreats,
        component: Retreats,

      },
      {
        path: '/content/retreats/new',
        name: routeNames.routeRetreatsNew,
        component: RetreatsEdit,
      },
      {
        path: '/content/retreats/:id',
        name: routeNames.routeRetreatsEdit,
        component: RetreatsEdit,
      },

      {
        path: '/content/treatments',
        name: routeNames.routeTreatments,
        component: Treatments,

      },
      {
        path: '/content/treatments/new',
        name: routeNames.routeTreatmentsNew,
        component: TreatmentsEdit,
      },
      {
        path: '/content/treatments/:id',
        name: routeNames.routeTreatmentsEdit,
        component: TreatmentsEdit,
      },
      {
        path: '/content/activities',
        name: routeNames.routeActivities,
        component: Activities,

      },
      {
        path: '/content/activities/new',
        name: routeNames.routeActivitiesNew,
        component: ActivitiesEdit
      },
      {
        path: '/content/activities/:id',
        name: routeNames.routeActivitiesEdit,
        component: ActivitiesEdit
      },

      {
        path: '/content/healthstatuses',
        name: routeNames.routeHealthStatuses,
        component: HealthstatusList,

      },
      {
        path: '/content/healthstatuses/new',
        name: routeNames.routeHealthStatusesNew,
        component: HealthstatusEdit
      },
      {
        path: '/content/healthstatuses/:id',
        name: routeNames.routeHealthStatusesEdit,
        component: HealthstatusEdit
      },
      {
        path: '/content/categories/handout',
        name: routeNames.routeCategoriesHandout,
        props: { type: 'handout' },
        component: Categories,

      },
      {
        path: '/content/categories/handout/new',
        name: routeNames.routeCategoriesHandoutNew,
        component: CategoriesEdit,
        props: { type: 'handout' }
      },
      {
        path: '/content/categories/handout/:id',
        name: routeNames.routeCategoriesHandoutEdit,
        props: { type: 'handout' },
        component: CategoriesEdit
      },
      {
        path: '/content/categories/recipe',
        name: routeNames.routeCategoriesRecipe,
        props: { type: 'recipe' },
        component: Categories,
      },
      {
        path: '/content/categories/recipe/new',
        name: routeNames.routeCategoriesRecipeNew,
        component: CategoriesEdit,
        props: { type: 'recipe' }
      },
      {
        path: '/content/categories/recipe/:id',
        name: routeNames.routeCategoriesRecipeEdit,
        props: { type: 'recipe' },
        component: CategoriesEdit,
      },
      {
        path: '/content/recipes',
        name: routeNames.routeRecipes,
        component: Recipes,
      },
      {
        path: '/content/recipes/new',
        name: routeNames.routeRecipesNew,
        component: RecipesEdit
      },
      {
        path: '/content/recipes/:id',
        name: routeNames.routeRecipesEdit,
        component: RecipesEdit,
      },
      {
        path: '/content/handouts',
        name: routeNames.routeHandouts,
        component: Handouts,

      },
      {
        path: '/content/handouts/new',
        name: routeNames.routeHandoutsNew,
        component: HandoutsEdit
      },
      {
        path: '/content/handouts/:id',
        name: routeNames.routeHandoutsEdit,
        component: HandoutsEdit
      },
      /* ROUTES CONTENT END */
      /*   ROUTES PERSONELL */
      {
        path: '/staff/personnel',
        name: routeNames.routePersonnel,
        component: PersonnelList,
      },
      {
        path: '/staff/personnel/new',
        name: routeNames.routePersonnelNew,
        component: PersonnelEdit
      },
      {
        path: '/staff/personnel/:id',
        name: routeNames.routePersonnelEdit,
        component: PersonnelEdit
      },
      /*   ROUTES PERSONELL END */
      /*   ROUTES MEDIA  */
      {
        path: '/media',
        name: routeNames.routeMedia,
        component: Media,
      },
      {
        path: '/media/new',
        name: routeNames.routeMediaNew,
        component: MediaEdit,
      },
      {
        path: '/media/:id',
        name: routeNames.routeMediaEdit,
        component: MediaEdit,
      },
      /*   ROUTES MEDIA END */
      /*   ROUTES GUEST */
      {
        path: '/guests',
        name: routeNames.routeGuests,
        component: GuestsList,
      },
      {
        path: '/stays',
        name: routeNames.routeAllStays,
        component: StaysList,
      },

      {
        path: '/staysessions',
        name: routeNames.routeAllSessions,
        component: StaySessions,
      },
      
     /* {
        path: '/stays/:id',
        name: 'Guest Stay',
        component: StayDetails,
      },*/
      {
        path: '/guests/new',
        name: routeNames.routeGuestsNew,
        component: GuestNew,
      },
     /* {
        path: '/guests/:id/stay/:stayId/staysessions',
        name: 'Guest Listing',
        component: GuestsList,
      }, */
      {
        path: '/guests/:id',
        component: GuestDetails,
        name: routeNames.routeGuestMain,
        redirect: '/guests/:id/profile',
        children: [
          {
            path: '/guests/:id/profile',
            name: routeNames.routeGuestProfile,
            component: GuestEdit,
          },
          {
            path: '/guests/:id/stays',
            name: routeNames.routeGuestStays,
            component: GuestStays,
          },
          {
            path: '/guests/:id/stays/new',
            name: routeNames.routeGuestStayNew,
            component: GuestsStayAdd
          },
          {
            path: '/guests/:id/stays/:stayId',
            name: routeNames.routeGuestStayEdit,
            component: GuestStayEdit,
          },
          {
            path: '/guests/:id/medias',
            name: routeNames.routeGuestFiles,
            component: GuestMedia,
          },
          {
            path: '/guests/:id/medias/new',
            name: routeNames.routeGuestFilesNew,
            component: GuestMediaEdit,
          },
          {
            path: '/guests/:id/medias/:mediaId',
            name: routeNames.routeGuestFilesEdit,
            component: GuestMediaEdit,
          },
          {
            path: '/guests/:id/healthstatus',
            name: routeNames.routeGuestHealthStatus,
            component: GuestHealthStatuses
          },
          {
            path: '/guests/:id/healthstatus/new',
            name: routeNames.routeGuestHealthStatusNew,
            component: GuestHealthStatusEdit
          },
          {
            path: '/guests/:id/healthstatus/:healthstatusId',
            name: routeNames.routeGuestHealthStatusEdit,
            component: GuestHealthStatusEdit
          },
          {
            path: '/guests/:id/medicalresults',
            name: routeNames.routeGuestMedicalTests,
            component: GuestMedicalResults
          },
          {
            path: '/guests/:id/medicalresults/new',
            name: routeNames.routeGuestMedicalTestsNew,
            component: GuestMedicalResulEdit
          },
          {
            path: '/guests/:id/medicalresults/:medicalresultId',
            name: routeNames.routeGuestMedicalTestsEdit,
            component: GuestMedicalResulEdit
          },

          {
            path: '/guests/:id/wellnessplan',
            name: routeNames.routeGuestWellnessPlan,
            component: GuestWellnessPlans
          },
          {
            path: '/guests/:id/wellnessplan/new',
            name: routeNames.routeGuestWellnessPlanNew,
            component: GuestWellnessPlan
          },
          {
            path: '/guests/:id/wellnessplan/:wellnessPlanId',
            name: routeNames.routeGuestWellnessPlanEdit,
            component: GuestWellnessPlan
          },

          {
            path: '/guests/:id/surveys',
            name: routeNames.surveys,
            component: Surveys
          },
          {
            path: '/guests/:id/surveys/:surveyId',
            name: routeNames.surveyView,
            component: SurveyView
          },
          
        ]
      },
      {
        path: '/check-in',
        name: routeNames.routeCheckIn,
        component: CheckIn
      },    

      
      {
        path: '/content/policy',
        name: routeNames.routePolicyEdit,
        component: PolicyEdit
      },
      {
        path: '/content/terms',
        name: routeNames.routeTermsEdit,
        component: TermsEdit
      },
      {
        path: '/content/cms',
        name: routeNames.cms,
        component: CMS
      },
      {
        path: '/content/cms/new',
        name: routeNames.cmsNew,
        component: CmsEdit
      },
      {
        path: '/content/cms/edit/:id',
        name: routeNames.cmsNew,
        component: CmsEdit
      },
      {
        path: '/notifications/devices',
        name: routeNames.devices,
        component: Devices
      },
      {
        path: '/notifications/history',
        name: routeNames.history,
        component: History
      },
      {
        path: '/notifications/queue',
        name: routeNames.notifications,
        component: Notifications
      },
      {
        path: '/notifications/send',
        name: routeNames.sendMessage,
        component: SendMessage
      },
    ]
  },
  authPages,
];

export default routes;
