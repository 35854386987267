<template>
  <bread-crumb list-classes="breadcrumb-links breadcrumb-dark">

    <BreadCrumbItem>
        <router-link to="/">
          <i class="fas fa-home"></i>
        </router-link>
    </BreadCrumbItem>

    <BreadCrumbItem
      v-for="(breadcrumb, index) in (crumbs || [])"
      :key="index"
      style="display:inline-block"
    >
      <router-link v-if="breadcrumb.route"
        :to="{ name: breadcrumb.route, params: breadcrumb.params }"
      >
        {{ breadcrumb.label }}
      </router-link>     
        <span v-else class="text-muted text-light" style="">{{breadcrumb.label}}</span>
    </BreadCrumbItem>

    
    <!--<BreadCrumbItem>
      <span class="text-muted text-light" style="">{{$route.name}}</span>
    </BreadCrumbItem>-->
  </bread-crumb>
</template>

<script>
  import BreadCrumb from './Breadcrumb';
  import BreadCrumbItem from './BreadcrumbItem';

  export default {
    name: 'route-breadcrumb',
    props: [
      'crumbs'
    ],
    components: {
      BreadCrumb,
      BreadCrumbItem
    },
    methods: {
      getBreadName(route) {
        return route.name;
      }
    }
  };
</script>

<style scoped></style>
