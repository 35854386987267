
 <template>
  <modal @close="close" :show="showModal">
    <b-overlay :show="isBusy">
      <div>
        <b-card no-body>
          <b-tabs v-model="selectedTab" pills card class="nav-tabs">
            <b-tab title="Password" active>
              <template #title>
                <i class="fas fa-key mr-2"></i>
                Password
              </template>
              <b-card-text>
                <validation-observer
                  v-slot="{ handleSubmit }"
                  ref="formValidator"
                >
                  <b-form
                    class="needs-validation"
                    @submit.prevent="handleSubmit(itemFormPasswordSubmit)"
                    novalidate
                  >
                    <div class="">
                      <b-row>
                        <b-col lg="12">
                          <base-input
                            type="password"
                            :name="'Old Password'"
                            label="Old password"
                            :required="true"
                            placeholder="Type your current password"
                            v-model="itemForm.currentPassword"
                          >
                          </base-input>
                        </b-col>

                        <b-col lg="12">
                          <base-input
                            type="password"
                            :name="'Password'"
                            label="New Password"
                            :required="true"
                            placeholder="New Password"
                            v-model="itemForm.password"
                          >
                          </base-input>
                        </b-col>
                        <b-col lg="12">
                          <base-input
                            type="password"
                            :name="'Repeat Password'"
                            label="Repeat Password"
                            :required="true"
                            placeholder="Repeat Password"
                            v-model="itemForm.confirmedPassword"
                          >
                          </base-input>
                        </b-col>
                      </b-row>
                    </div>
                  </b-form>
                </validation-observer> </b-card-text
            ></b-tab>
            <b-tab>
              <template #title>
                <i class="fas fa-user-tag mr-2"></i>
                Personal Info
              </template>

              <b-card-text>
                <validation-observer
                  v-slot="{ handleSubmit }"
                  ref="formValidator2"
                >
                  <b-form
                    class="needs-validation"
                    @submit.prevent="handleSubmit(itemFormInfoSubmit)"
                    novalidate
                  >
                    <div class="">
                      <b-row>
                        <b-col lg="6">
                          <base-input
                            type="text"
                            :name="'First Name'"
                            label="First Name"
                            :required="true"
                            placeholder="First Name"
                            v-model="itemInfoForm.first_name"
                          >
                          </base-input>
                        </b-col>

                        <b-col lg="6">
                          <base-input
                            type="text"
                            :name="'Last Name'"
                            label="Last Name"
                            :required="true"
                            placeholder="Last Name"
                            v-model="itemInfoForm.last_name"
                          >
                          </base-input>
                        </b-col>
                      </b-row>
                    </div>
                  </b-form>
                </validation-observer> </b-card-text
            ></b-tab>
          </b-tabs>
        </b-card>
      </div>
    </b-overlay>

    <template slot="footer">
      <div>
        <base-button @click="close" type="link" class="ml-auto"
          >Cancel</base-button
        >

        <base-button
          v-if="selectedTab === 0"
          @click="itemFormPasswordSubmit"
          type="primary"
        >
          Change Password
        </base-button>

        <base-button
          v-if="selectedTab === 1"
          @click="itemFormInfoSubmit"
          type="primary"
        >
          Update info
        </base-button>
      </div>
    </template>
  </modal>
</template>
 
 
 
 
 
 
 <script>
import * as UserRepo from "@/api-repo/user";
import { handleError, successMessage } from "@/util/error-handler";

export default {
  name: "modal-profile-form",
  inject: ["userStore"],
  components: {},
  props: ["show", "showModal", "staySessionId", "passedDatetimeInfo"],
  data() {
    return {
      selectedTab: 0,
      statuses: [
        { _id: "scheduled", name: "Scheduled" },
        { _id: "attended", name: "Attended" },
      ],
      isBusy: false,
      advisors: [],
      allServices: [],
      retreats: [],
      itemForm: {
        password: null,
        currentPassword: null,
        confirmedPassword:null
      },
      itemInfoForm: {
        first_name: null,
        last_name: null,
      },
    };
  },

  async created() {
    await this.getInitData();
  },

  methods: {
    getInitData: async function () {
      let result = await UserRepo.getProfile(this.userStore.state.token);
      this.itemInfoForm.first_name = result.data.data.first_name;
      this.itemInfoForm.last_name = result.data.data.last_name;
    },

    close() {
      this.reset();
      this.$emit("closeProfileModal");
    },

    reset() {
      Object.keys(this.itemForm).forEach((key) => {
        this.itemForm[key] = null;
      });
    },

    itemFormPasswordSubmit: async function () {
      const isValid = await this.$refs.formValidator.validate();

      if (isValid) {
        let itemsForSubmission = {
          ...this.itemForm,
        };
        try {
          this.isBusy = true;
          let res = await UserRepo.changePassword(
            itemsForSubmission,
            this.userStore.state.token
          );
          successMessage("Password updated successfully");
          this.close();
        } catch (e) {
          handleError(e);
        } finally {
          this.isBusy = false;
        }
      }
    },

    itemFormInfoSubmit: async function () {
      const isValid = await this.$refs.formValidator2.validate();

      if (isValid) {
        let itemsForSubmission = {
          ...this.itemInfoForm,
        };
        try {
          this.isBusy = true;
          let res = await UserRepo.updateName(
            this.userStore.state.user._id,
            itemsForSubmission,
            this.userStore.state.token
          );
          successMessage("Name updated successfully");
          this.close();
        } catch (e) {
          handleError(e);
        } finally {
          this.isBusy = false;
        }
      }
    },
  },
};
</script>