import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import axios from 'axios';


// router setup
import router from './routes/router';
//redirect to login on 401 error
axios.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    
    if (error.response.status === 401 && !error.response.config.url.includes('signin')) {
        App.provide.userStore.signOut()
        return
      }
    return Promise.reject(error);
  }
)
App.provide.userStore.initialize()
// plugin setup
Vue.use(DashboardPlugin);


Vue.mixin({
  methods: {
    checkAccess: function (taxonomy, operation) {
      let user = App.provide.userStore.state.user;
      if (user && user.role) {
        if (user.username == "admin") {
          return true;
        }
        if (taxonomy in user.role.permissions) {
          if (user.role.permissions[taxonomy][operation] == true ) {
            return true;
          }
        }
      }
      return false;
    },
  },
})

/* eslint-disable no-new */


new Vue({
  el: '#app',
  render: h => h(App),
  created() {
    


  },
  router
});
