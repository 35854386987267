<template>
<div >
<span @click="handleClick" class="p-2" role="button">
<i  
class="fas fa-lg fa-trash-alt deleteBtn"></i>
</span>
    <modal :show.sync="showDeleteModal">
              <h6 slot="header" class="modal-title">Confirm Delete</h6>

              <p>Are you sure you want to delete this record?</p>

              <template slot="footer">
              <div>
                <base-button type="link" class="ml-auto" @click="showDeleteModal = false">Cancel</base-button>
                <base-button @click='handleDelete' type="primary">Yes</base-button>
                </div>
              </template>

            </modal>

</div>

</template>



<script>
export default {
  name: 'close-button',
   data() {
    return {
      showDeleteModal: false
    }
    },
  props: {
    expanded: {
      type: Boolean,
      description: 'Whether button is expanded (aria-expanded attribute)'
    }
  },
  methods: {
    handleClick(evt) {
      this.showDeleteModal = true;
    },

    handleDelete(evt){
      this.$emit('delete', evt);
      this.showDeleteModal = false;
    }
  }
};
</script>
<style scoped>
.deleteBtn{
  color: #3897B6;
  cursor: pointer;
}

</style>
