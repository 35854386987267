import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
//import { Modal, BaseAlert } from '@/components';

export const handleError = (error, callback = null) => {
  let message = error.response && error.response.data && error.response.data.message || error
  console.log(error.response)
  if(error.response.data.errors){
    message = error.response.data.errors[0].msg;
  }
  swal.fire({
    text: message,
    buttonsStyling: false,
    confirmButtonClass: 'btn btn-primary',
    icon: 'error'
  })
}

export const successMessage = (message, callback = null) => {
  
  swal.fire({
    text: message,
    buttonsStyling: false,
    confirmButtonClass: 'btn btn-primary',
    icon: 'success'
  })
}
