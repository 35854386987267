var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          path: '/dashboard',
          icon: 'fas fa-home',
        }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkAccess('media', 'edit') || _vm.checkAccess('media', 'view')),expression:"checkAccess('media', 'edit') || checkAccess('media', 'view')"}],attrs:{"link":{ name: 'Media', path: '/media', icon: 'far fa-image ' }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkAccess('cms', 'edit') || _vm.checkAccess('cms', 'view')),expression:"checkAccess('cms', 'edit') || checkAccess('cms', 'view')"}],attrs:{"link":{
          name: 'Content',
          icon: 'far fa-file-alt ',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Retreats', path: '/content/retreats' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Treatments', path: '/content/treatments' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Activities', path: '/content/activities' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Videos', path: '/content/videos' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Recipe Categories',
            path: '/content/categories/recipe',
          }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Recipes', path: '/content/recipes' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Handout Categories',
            path: '/content/categories/handout',
          }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Handouts', path: '/content/handouts' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Health Conditions',
            path: '/content/healthstatuses',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Privacy Policy',
            path: '/content/policy',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'T&C',
            path: '/content/terms',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Key Values',
            path: '/content/cms',
          }}})],1),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.checkAccess('guests', 'edit') || _vm.checkAccess('guests', 'view')
        ),expression:"\n          checkAccess('guests', 'edit') || checkAccess('guests', 'view')\n        "}],attrs:{"link":{ name: 'Guests', path: '/guests', icon: 'far fa-user ' }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.checkAccess('guests', 'edit') || _vm.checkAccess('guests', 'view')
        ),expression:"\n          checkAccess('guests', 'edit') || checkAccess('guests', 'view')\n        "}],attrs:{"link":{ name: 'Stays', path: '/stays', icon: 'fa fa-bed ' }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.checkAccess('guests', 'edit') || _vm.checkAccess('guests', 'view')
        ),expression:"\n          checkAccess('guests', 'edit') || checkAccess('guests', 'view')\n        "}],attrs:{"link":{ name: 'Stay sessions', path: '/staysessions', icon: 'fas fa-user-clock' }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.checkAccess('checkin', 'edit') || _vm.checkAccess('checkin', 'view')
        ),expression:"\n          checkAccess('checkin', 'edit') || checkAccess('checkin', 'view')\n        "}],attrs:{"link":{
          name: 'Check In',
          path: '/check-in',
          icon: 'fas fa-clipboard-check',
        }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.checkAccess('personnel', 'edit') || _vm.checkAccess('personnel', 'view')
        ),expression:"\n          checkAccess('personnel', 'edit') || checkAccess('personnel', 'view')\n        "}],attrs:{"link":{
          name: 'Manage Staff',
          icon: 'fas fa-user-md ',
        }}},[_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.checkAccess('personnel', 'edit') ||
            _vm.checkAccess('personnel', 'view')
          ),expression:"\n            checkAccess('personnel', 'edit') ||\n            checkAccess('personnel', 'view')\n          "}],attrs:{"link":{ name: 'Personnel', path: '/staff/personnel' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Notifications',
          icon: 'fas fa-bell ',

        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Devices', path: '/notifications/devices' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'History', path: '/notifications/history' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Queue', path: '/notifications/queue' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Add Message(s)', path: '/notifications/send' }}})],1),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkAccess('users', 'edit') || _vm.checkAccess('users', 'view')),expression:"checkAccess('users', 'edit') || checkAccess('users', 'view')"}],attrs:{"link":{
          name: 'Security',
          icon: 'fas fa-shield-alt ',
        }}},[_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.checkAccess('users', 'edit') || _vm.checkAccess('users', 'view')
          ),expression:"\n            checkAccess('users', 'edit') || checkAccess('users', 'view')\n          "}],attrs:{"link":{ name: 'Users', path: '/security/users' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Roles', path: '/security/roles' }}})],1)],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }