import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)
import { reactive, readonly } from "@vue/composition-api";
import * as userRepo from "@/api-repo/user"

const state = reactive({
  user: null,
  busy: false,
  token: null
});

const signOut = async function() {
  state.user = null;
  state.busy = false;
  state.token = null;
  sessionStorage.removeItem("token");
}

const logIn = async function(username, password) {
  if (state.busy) return;
  state.busy = true;
  try {
    let result = await userRepo.login(username, password);
    state.user = result && result.data && result.data.data.user;
    state.token = result && result.data && result.data.data.access_token;
    state.busy = false;
    sessionStorage.setItem("token", state.token)
  } catch (e) {
    state.busy = false;
    throw e;
  }
}

const getProfile = async function() {

  if (!state.token || state.busy) return;
  state.busy = true;
  try {
    let result = await userRepo.getProfile(state.token);
    state.user = result && result.data && result.data.data;
    state.busy = false;
  } catch (e) {
    state.busy = false;
    throw e;
  }
}

const initialize = async function() {
  state.token = sessionStorage.getItem("token");

  if (state.token) {
  
    getProfile();
  }
}

export default {
  state: readonly(state),
  signOut,
  logIn,
  getProfile,
  initialize
};
