<template>
    <div class="card-body p-3">
        <div class="row gx-4">
        <div class="col-auto">
        <div class="avatar avatar-xl position-relative">
        <!-- <img src="../assets/img/team-1.jpg" alt="profile_image" class="w-100 border-radius-lg shadow-sm"> -->
        <i class="fas fa-user display-2"> </i>
        </div>
        </div>
        <div class="col-auto my-auto">
        <div class="h-100">
        <h5 class="mb-1">
        {{title}} {{fullname}}
        </h5>
        <p class="mb-0 font-weight-bold text-sm">
         {{type}}
        </p>
        </div>
        </div>
   <!--      <div class="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
        <div class="nav-wrapper position-relative end-0">
        <ul class="nav nav-pills nav-fill p-1" role="tablist">
        <li class="nav-item" role="presentation">
        <a class="nav-link mb-0 px-0 py-1 d-flex align-items-center justify-content-center" data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="false" tabindex="-1">
        <i class="ni ni-app"></i>
        <span class="ms-2">App</span>
        </a>
        </li>
        <li class="nav-item" role="presentation">
        <a class="nav-link mb-0 px-0 py-1 d-flex align-items-center justify-content-center" data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="false" tabindex="-1">
        <i class="ni ni-email-83"></i>
        <span class="ms-2">Messages</span>
        </a>
        </li>
        <li class="nav-item" role="presentation">
        <a class="nav-link mb-0 px-0 py-1 d-flex align-items-center justify-content-center active" data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true">
        <i class="ni ni-settings-gear-65"></i>
        <span class="ms-2">Settings</span>
        </a>
        </li>
        <div class="moving-tab position-absolute nav-link" style="padding: 0px; transition: all 0.5s ease 0s; transform: translate3d(257px, 0px, 0px); width: 136px;"><a class="nav-link mb-0 px-0 py-1 d-flex align-items-center justify-content-center active" data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true">-</a></div></ul>
        </div>
         </div> -->
        </div>
        </div>

</template>


<script>
   export default {
    name: 'base-guest-card',
    props: {
      title: {
        type: String,
        default:  '',
        description: 'Guest title'
      },
      fullname: {
        type: String,
        default:  '',
        description: 'Guest fullname'
      },
      type: {
        type: String,
        default:  'Guest',
        description: 'Guest type'
      }
    }
    
  }
  </script>

