<template>


  <router-view :key="$route.fullPath"></router-view>

</template>

<script>
import userStore from '@/store/user'

export default {
  provide: {
    userStore,
  },
  computed: {
    isLoggedIn() {
      return userStore.state.token
    }
  },
  watch: {
    isLoggedIn(after, before) {
      if (after == null && before != null) {
        this.$router.replace({name: 'Login'})
      }

    }
  },
}
</script>
