import axios from "axios"

export const login = (username, password) => {
  return axios.post(process.env.VUE_APP_API_URL + `user/signin`, {
    username: username,
    password: password
  })
}

export const create = (user, token) => {
  return axios.post(process.env.VUE_APP_API_URL + `user/create`, {
    username: user.username,
    password: user.password,
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    role: user.role,
    locked: false
  }, {
      headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const update = (id, user, token) => {
  return axios.put(process.env.VUE_APP_API_URL + `user/` + id, {
    //username: user.username,
    //password: user.password,
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    role: user.role,
    locked: user.locked
  }, {
      headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}


export const updateName = (id, user, token) => {
  return axios.put(process.env.VUE_APP_API_URL + `user/` + id + '/update-name', {
    first_name: user.first_name,
    last_name: user.last_name,
  }, {
      headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}



export const changePassword = (itemForm, token) => {
  return axios.put(process.env.VUE_APP_API_URL + `user/self/reset-password`, {
    ...itemForm,
  }, {
      headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const getById = (id, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `user/` + id, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}


export const getProfile = (token) => {
  return axios.get(process.env.VUE_APP_API_URL + `user/self`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}


export const getAll = (search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `user/?search=${search}&count=${count}&page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}
