import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import App from '../App.vue';

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  if (
    
    // make sure the user is authenticated
    !App.provide.userStore.state.token &&
    // ❗️ Avoid an infinite redirect
    to.name !== 'Login' && to.name !== 'Reset'
  ) {
    console.log("route guard")
    // redirect the user to the login page
    next({ name: 'Login' });
  } else {
    next();
  }

})


export default router;
